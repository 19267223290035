/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
// Boolean indicating whether this is an article:
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Trans} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
const MDXLayout = Layout;
import Seo from "../components/Seo";
export const Head = props => React.createElement(Seo, {
  title: props.pageContext.frontmatter?.title,
  description: props.pageContext.frontmatter?.description,
  image: props.pageContext.frontmatter?.image,
  article: true
});
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, React.createElement(Trans, null, "Nos marchés sont variés, articulés autour des piliers du complément alimentaire et de la cosmétique")), "\n", React.createElement(Trans, null, "Paragraphe1."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
